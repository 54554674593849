import Vue from "vue";
import axios from "axios";
import { DataSourceURL } from "../global/variables";

export default Vue.extend({
  data() {
    return {
      //Private

      AvdelingItemsFirmaID: [] as Array<any>,
      TaskItemsProsjektID: [] as Array<any>,
      TaskItemsSprintID: [] as Array<any>,
      PostNummerItems: [] as Array<any>,

      //Commen

      AktuellForTypeItems: [] as Array<any>,
      AvdelingItems: [] as Array<any>,
      AvvikBehandlingItems: [] as Array<any>,
      AvvikItems: [] as Array<any>,
      AvvikStatusItems: [] as Array<any>,
      BrukerItems: [] as Array<any>,
      ChickenTilgangItems: [] as Array<any>,
      DokumentTypeItems: [] as Array<any>,
      ErfaringItems: [] as Array<any>,
      FirmaRolleItems: [] as Array<any>,
      JobbTittelItems: [] as Array<any>,
      KatogoriItems: [] as Array<any>,
      MoteItems: [] as Array<any>,
      ProsjektRolleItems: [] as Array<any>,
      ProsjektStatusItems: [] as Array<any>,
      RegionItems: [] as Array<any>,
      SakItems: [] as Array<any>,
      StillingsTypeItems: [] as Array<any>,
      TaskItems: [] as Array<any>,
      TaskStatusItems: [] as Array<any>,
      TaskTypeItems: [] as Array<any>,
      VedleggDescTypeItems: [] as Array<any>,
      VedleggMetaItems: [] as Array<any>,
    };
  },
  methods: {
    FindDescription(ID: any, myList: Array<any>) {
      let i = 0;
      for (i = 0; i < myList.length; i++) {
        if (myList[i].Value === ID) {
          return myList[i].Description;
        }
      }
      return "";
    },

    FindCode(Description: string, myList: Array<any>) {
      let i = 0;
      for (i = 0; i < myList.length; i++) {
        if (myList[i].Description === Description) {
          return myList[i].Value;
        }
      }
      return "";
    },

    formatDate(value: any) {
      if (value === undefined) {
        return "";
      } else if (!value) {
        return "";
      } else {
        const lang = window.navigator.language;
        return new Date(value).toLocaleDateString(lang);
      }
    },

    formatDateTime(value: any) {
      if (value === undefined) {
        return "";
      } else if (!value) {
        return "";
      } else {
        const lang = window.navigator.language;
        return new Date(value).toLocaleString(lang);
      }
    },

    // just helper to get current dates
    getDate(hours: any) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const currentDay = currentDate.getDate();
      const timeStamp = new Date(
        `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
      ).getTime();
      return new Date(timeStamp + hours * 60 * 60 * 1000);
    },
    //Private

    GetAvdelingCodeFirmaID(FirmaID: number) {
      axios({
        method: "get",
        params: { FirmaID: FirmaID },
        url: DataSourceURL + "/codePrivate/GetAvdelingCodeFirmaID",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.AvdelingItemsFirmaID = res.data.DataList;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /codePrivate/GetAvdelingCodeFirmaID. See details: ${err}`
          );
        });
    },

    GetTaskCodeSprintID(SprintID: number) {
      if (this.TaskItemsSprintID.length == 0) {
        //Lazy load
        axios({
          method: "get",
          params: { SprintID: SprintID },
          url: DataSourceURL + "/codePrivate/GetTaskCodeSprintID",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.TaskItemsSprintID = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetTaskCode. See details: ${err}`
            );
          });
      }
    },

    GetTaskCodeProsjektID(ProsjektID: number) {
      if (this.TaskItemsProsjektID.length == 0) {
        //Lazy load
        axios({
          method: "get",
          params: { ProsjektID: ProsjektID },
          url: DataSourceURL + "/codePrivate/GetTaskCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.TaskItemsProsjektID = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetTaskCode. See details: ${err}`
            );
          });
      }
    },
    GetPostNummerCode() {
      if (this.PostNummerItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/codePrivate/GetPostNummerCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.PostNummerItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetPostNummerCode. See details: ${err}`
            );
          });
      }
    },

    //Commen

    GetAktuellForTypeCode() {
      if (this.AktuellForTypeItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetAktuellForTypeCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.AktuellForTypeItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetAktuellForTypeCode. See details: ${err}`
            );
          });
      }
    },

    GetAvdelingCode() {
      if (this.AvdelingItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetAvdelingCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.AvdelingItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetAvdelingCode. See details: ${err}`
            );
          });
      }
    },

    GetAvvikBehandlingCode() {
      if (this.AvvikBehandlingItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetAvvikBehandlingCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.AvvikBehandlingItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetAvvikBehandlingCode. See details: ${err}`
            );
          });
      }
    },

    GetAvvikCode() {
      if (this.AvvikItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetAvvikCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.AvvikItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetAvvikCode. See details: ${err}`
            );
          });
      }
    },

    GetAvvikStatusCode() {
      if (this.AvvikStatusItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetAvvikStatusCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.AvvikStatusItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetAvvikStatusCode. See details: ${err}`
            );
          });
      }
    },

    GetBrukerCode() {
      if (this.BrukerItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetBrukerCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.BrukerItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetBrukerCode. See details: ${err}`
            );
          });
      }
    },

    GetChickenTilgangCode() {
      if (this.ChickenTilgangItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetChickenTilgangCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.ChickenTilgangItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetChickenTilgangCode. See details: ${err}`
            );
          });
      }
    },

    GetDokumentTypeCode() {
      if (this.DokumentTypeItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetDokumentTypeCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.DokumentTypeItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetDokumentTypeCode. See details: ${err}`
            );
          });
      }
    },

    GetErfaringCode() {
      if (this.ErfaringItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetErfaringCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.ErfaringItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetErfaringCode. See details: ${err}`
            );
          });
      }
    },

    GetFirmaRolleCode() {
      if (this.FirmaRolleItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetFirmaRolleCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.FirmaRolleItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetFirmaRolleCode. See details: ${err}`
            );
          });
      }
    },

    GetJobbTittelCode() {
      if (this.JobbTittelItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetJobbTittelCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.JobbTittelItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetJobbTittelCode. See details: ${err}`
            );
          });
      }
    },

    GetKatogoriCode() {
      if (this.KatogoriItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetKatogoriCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.KatogoriItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetKatogoriCode. See details: ${err}`
            );
          });
      }
    },

    GetMoteCode() {
      if (this.MoteItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetMoteCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.MoteItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetMoteCode. See details: ${err}`
            );
          });
      }
    },

    GetProsjektRolleCode() {
      if (this.ProsjektRolleItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetProsjektRolleCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.ProsjektRolleItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetProsjektRolleCode. See details: ${err}`
            );
          });
      }
    },

    GetProsjektStatusCode() {
      if (this.ProsjektStatusItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetProsjektStatusCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.ProsjektStatusItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetProsjektStatusCode. See details: ${err}`
            );
          });
      }
    },

    GetRegionCode() {
      if (this.RegionItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetRegionCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.RegionItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetRegionCode. See details: ${err}`
            );
          });
      }
    },

    GetSakCode() {
      if (this.SakItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetSakCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.SakItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetSakCode. See details: ${err}`
            );
          });
      }
    },

    GetStillingsTypeCode() {
      if (this.StillingsTypeItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetStillingsTypeCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.StillingsTypeItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetStillingsTypeCode. See details: ${err}`
            );
          });
      }
    },

    GetTaskCode() {
      if (this.TaskItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetTaskCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.TaskItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetTaskCode. See details: ${err}`
            );
          });
      }
    },

    GetTaskStatusCode() {
      if (this.TaskStatusItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetTaskStatusCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.TaskStatusItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetTaskStatusCode. See details: ${err}`
            );
          });
      }
    },

    GetTaskTypeCode() {
      if (this.TaskTypeItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetTaskTypeCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.TaskTypeItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetTaskTypeCode. See details: ${err}`
            );
          });
      }
    },

    GetVedleggDescTypeCode() {
      if (this.VedleggDescTypeItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetVedleggDescTypeCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.VedleggDescTypeItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetVedleggDescTypeCode. See details: ${err}`
            );
          });
      }
    },

    GetVedleggMetaCode() {
      if (this.VedleggMetaItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetVedleggMetaCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              this.VedleggMetaItems = res.data.DataList;
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetVedleggMetaCode. See details: ${err}`
            );
          });
      }
    },
  },
});
