<template>
  <v-container>
    <v-row>
      <v-col>
        <v-treeview
          v-model="active"
          :active.sync="active"
          :items="items"
          activatable
          color="warning"
          open-on-click
          transition
        ></v-treeview>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col class="pa-6" cols="6">
        <dlgBruker v-if="dialog" v-model="editedItem" :editMode="0" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import { DataSourceURL } from "../global/variables";
import KeyValue from "../global/KeyValue";

export default {
  extends: KeyValue,
  components: {
    dlgBruker: () => import("../dialog/dlgBruker"),
  },
  data: () => ({
    active: [],
    items: [],
    dialog: false,
    editedItem: {
      BrukerID: "",
      FirmaID: "",
      ChickenTilgangID: "",
      StillingsTypeID: "",
      JobbTittelID: "",
      AvdelingID: "",
      Navn: "",
      Tlf1: "",
      Tlf2: "",
      Tlf3: "",
      eMail1: "",
      eMail2: "",
      Notat: "",
      Passord: "",
      Token: "",
      TokenTimeOut: "",
    },
  }),
  created() {
    this.GetAvdelingCode();
    this.GetChickenTilgangCode();
    this.GetJobbTittelCode();
    this.GetStillingsTypeCode();
    this.GetData();
  },
  //     computed: {
  //       selected () {
  //         if (!this.active.length) return undefined

  //         const id = this.active[0]
  // alert(id);
  //         return this.items.find(items => items.id === id)
  //       },
  //     },
  methods: {
    GetData() {
      axios({
        method: "get",
        url: DataSourceURL + "/PartnerTree/GetPartnerData",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          this.isLoading = false;
          if (!res.data.ErrorStatus) {
            this.items = res.data.DataList;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          this.isLoading = false;
          alert(
            `There was an error in reading /PartnerTree/GetPartnerData'. See details: ${err}`
          );
        });
    },
    GetBruker(BrukerID) {
      axios({
        method: "get",
        params: { BrukerID: BrukerID },
        url: DataSourceURL + "/Bruker/GetBruker",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.editedItem = res.data.DataList;
            this.dialog = true;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /Bruker/GetBruker. See details: ${err}`
          );
        });
    },
  },
  watch: {
    active(newVal, oldVal) {
      console.log("GGGGG");
      if (newVal !== oldVal) {
        if (String(newVal).slice(0, 1) === "C") {
          this.GetBruker(String(newVal).slice(1));
        }
      }
    },
  },
};
</script>
